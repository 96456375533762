/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import kz from "../locales/kz.json";
import ru from "../locales/ru.json";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import storage from "../services/storageService";
import { formatMessage } from "devextreme/localization";

// without trailing slash
//http://192.168.0.146:9001/

export const REACT_APP_IAM_SERVICE_BASE_URL =
  "https://edu-test-iam-service.azurewebsites.net/api";
export const REACT_APP_TEST_CENTRE_BASE_URL =
  "https://edu-test-test-centre-service.azurewebsites.net/api";
export const REACT_APP_TEST_RESULT_BASE_URL =
  "https://edu-test-test-results-service.azurewebsites.net/api";

export const REACT_APP_WEBSOCKET_URL =
  "https://edutest-test-results-service.azurewebsites.net/ws";

//test
// export const REACT_APP_IAM_SERVICE_BASE_URL = "http://localhost:9001/api";
// export const REACT_APP_TEST_CENTRE_BASE_URL = "http://localhost:9002/api";
// export const REACT_APP_TEST_RESULT_BASE_URL = "http://localhost:9003/api";

// export const REACT_APP_WEBSOCKET_URL = "http://192.168.0.108:9003/ws";

export const REACT_APP_PAYMENT_JS_PACK_URL =
  process.env.REACT_APP_PAYMENT_JS_PACK_URL ||
  "https://epay.homebank.kz/payform/payment-api.js";

export const LANGUAGE_LIST = ["EN", "KZ", "RU"];

export const GENDER_LIST = ["MALE", "FEMALE"];

export const NUMBER_OF_CLASSES = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
];

export const NUMBER_OF_CLASSES_V2 = [
  { id: "C1", name: "1" },
  { id: "C2", name: "2" },
  { id: "C3", name: "3" },
  { id: "C4", name: "4" },
  { id: "C5", name: "5" },
  { id: "C6", name: "6" },
  { id: "C7", name: "7" },
  { id: "C8", name: "8" },
  { id: "C9", name: "9" },
  { id: "C10", name: "10" },
  { id: "C11", name: "11" },
  { id: "C12", name: "12" },
  // {
  //   id: "C13",
  //   name: storage?.get("language") === "kz"
  //       ? kz.teacher
  //       : ru.teacher
  // },
];

export const FORMATTED_NUMBER_OF_CLASSES_V2 = (formatMessage) => {
  return [
    ...NUMBER_OF_CLASSES_V2,
    { id: "C13", name: formatMessage({ id: "teacher" }) },
  ];
};

export const ALPHABET = [
  { id: "1", name: "A" },
  { id: "2", name: "B" },
  { id: "3", name: "C" },
  { id: "4", name: "D" },
  { id: "5", name: "E" },
  { id: "6", name: "F" },
  { id: "7", name: "G" },
  { id: "8", name: "H" },
  { id: "9", name: "I" },
  { id: "10", name: "J" },
  { id: "11", name: "K" },
  { id: "12", name: "L" },
  { id: "13", name: "M" },
  { id: "14", name: "N" },
  { id: "15", name: "O" },
  { id: "16", name: "P" },
  { id: "17", name: "Q" },
  { id: "18", name: "R" },
  { id: "19", name: "S" },
  { id: "20", name: "T" },
  { id: "21", name: "U" },
  { id: "22", name: "V" },
  { id: "23", name: "W" },
  { id: "24", name: "X" },
  { id: "25", name: "Y" },
  { id: "26", name: "Z" },
];
export const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const KZ_LETTERS = [
  "А",
  "Ә",
  "Б",
  "В",
  "Г",
  "Ғ",
  "Д",
  "Е",
  "Ё",
  "Ж",
  "З",
  "И",
  "Й",
  "К",
  "Қ",
  "Л",
  "М",
  "Н",
  "Ң",
  "О",
  "Ө",
  "П",
  "Р",
  "С",
  "Т",
  "Ф",
];

//export const alphabet = "abcdefghijklmnopqrstuvwxyz";

export const QUESTION_TYPES = [
  "ONE_ANSWER",
  "TRUE_FALSE",
  "MULTI_SELECT",
  "SCALABLE",
  "MATCHING",
];

export const LANGUAGES = ["KZ", "RU", "EN"];

export const QUESTION_STATUSES = [
  "CREATED",
  "WAITING_APPROVAL",
  "PRE_APPROVED",
  "APPROVED",
  "REJECTED",
];

export const MONTH = [
  {id: 1, name: 'JANUARY'},
  {id: 2, name: 'FEBRUARY'},
  {id: 3, name: 'MARCH'},
  {id: 4, name: 'APRIL'},
  {id: 5, name: 'MAY'},
  {id: 6, name: 'JUNE'},
  {id: 7, name: 'JULY'},
  {id: 8, name: 'AUGUST'},
  {id: 9, name: 'SEPTEMBER'},
  {id: 10, name: 'OCTOBER'},
  {id: 11, name: 'NOVEMBER'},
  {id: 12, name: 'DECEMBER'}
]

export const MONTH_DATE_FORMAT = [
  {id: 0, name: 'JANUARY'},
  {id: 1, name: 'FEBRUARY'},
  {id: 2, name: 'MARCH'},
  {id: 3, name: 'APRIL'},
  {id: 4, name: 'MAY'},
  {id: 5, name: 'JUNE'},
  {id: 6, name: 'JULY'},
  {id: 7, name: 'AUGUST'},
  {id: 8, name: 'SEPTEMBER'},
  {id: 9, name: 'OCTOBER'},
  {id: 10, name: 'NOVEMBER'},
  {id: 11, name: 'DECEMBER'}
]

export const ENT_SUBJECTS = [
  {id: 6, title_kz: 'Физика', title_ru: 'Физика'},
  {id: 9, title_kz: 'Биология', title_ru: 'Биология'},
  {id: 10, title_kz: 'Математика', title_ru: 'Математика'},
  {id: 11, title_kz: 'Химия', title_ru: 'Химия'},
  {id: 12, title_kz: 'География', title_ru: 'География'},
  {id: 17, title_kz: 'Дүниежүзі тарихы', title_ru: 'Всемирная история'},
  {id: 18, title_kz: 'Орыс тілі', title_ru: 'Русский язык'},
  {id: 19, title_kz: 'Қазақ әдебиеті', title_ru: 'Казахская литература'},
  {id: 20, title_kz: 'Құқық негіздері', title_ru: 'Основа права'},
  {id: 30, title_kz: 'Ағылшын тілі', title_ru: 'Английский язык'},
  {id: 59, title_kz: 'Қазақ тілі', title_ru: 'Казахский язык'},
  {id: 169, title_kz: 'Орыс әдебиеті', title_ru: 'Русская литература'},
  {id: 452, title_kz: 'Информатика', title_ru: 'Информатика'},
]

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

var loading = false;
function setLoading(statusLoading) {
  loading = statusLoading;
}

export { loading, setLoading };
